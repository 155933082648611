import React from "react"
import Layout from "../components/layout"

const ColorsPage = props => {
  return (
    <Layout>
      <h1>Color palette</h1>
      <h2>Color ground</h2>
      <ul className="color-palette">
        <li className="color-swatch color-ground-100"></li>
        <li className="color-swatch color-ground-200"></li>
        <li className="color-swatch color-ground-300"></li>
        <li className="color-swatch color-ground-400"></li>
        <li className="color-swatch color-ground-500"></li>
        <li className="color-swatch color-ground-600"></li>
        <li className="color-swatch color-ground-700"></li>
        <li className="color-swatch color-ground-800"></li>
        <li className="color-swatch color-ground-900"></li>
      </ul>
      <h2>Color gold</h2>
      <ul className="color-palette">
        <li className="color-swatch color-gold-100"></li>
        <li className="color-swatch color-gold-200"></li>
        <li className="color-swatch color-gold-300"></li>
        <li className="color-swatch color-gold-400"></li>
        <li className="color-swatch color-gold-500"></li>
        <li className="color-swatch color-gold-600"></li>
        <li className="color-swatch color-gold-700"></li>
        <li className="color-swatch color-gold-800"></li>
        <li className="color-swatch color-gold-900"></li>
      </ul>
      <h2>Color olive</h2>
      <ul className="color-palette">
        <li className="color-swatch color-olive-100"></li>
        <li className="color-swatch color-olive-200"></li>
        <li className="color-swatch color-olive-300"></li>
        <li className="color-swatch color-olive-400"></li>
        <li className="color-swatch color-olive-500"></li>
        <li className="color-swatch color-olive-600"></li>
        <li className="color-swatch color-olive-700"></li>
        <li className="color-swatch color-olive-800"></li>
        <li className="color-swatch color-olive-900"></li>
      </ul>
      <h2>Color green</h2>
      <ul className="color-palette">
        <li className="color-swatch color-green-100"></li>
        <li className="color-swatch color-green-200"></li>
        <li className="color-swatch color-green-300"></li>
        <li className="color-swatch color-green-400"></li>
        <li className="color-swatch color-green-500"></li>
        <li className="color-swatch color-green-600"></li>
        <li className="color-swatch color-green-700"></li>
        <li className="color-swatch color-green-800"></li>
        <li className="color-swatch color-green-900"></li>
      </ul>
      <h2>Color pine</h2>
      <ul className="color-palette">
        <li className="color-swatch color-pine-100"></li>
        <li className="color-swatch color-pine-200"></li>
        <li className="color-swatch color-pine-300"></li>
        <li className="color-swatch color-pine-400"></li>
        <li className="color-swatch color-pine-500"></li>
        <li className="color-swatch color-pine-600"></li>
        <li className="color-swatch color-pine-700"></li>
        <li className="color-swatch color-pine-800"></li>
        <li className="color-swatch color-pine-900"></li>
      </ul>
      <h2>Color teal</h2>
      <ul className="color-palette">
        <li className="color-swatch color-teal-100"></li>
        <li className="color-swatch color-teal-200"></li>
        <li className="color-swatch color-teal-300"></li>
        <li className="color-swatch color-teal-400"></li>
        <li className="color-swatch color-teal-500"></li>
        <li className="color-swatch color-teal-600"></li>
        <li className="color-swatch color-teal-700"></li>
        <li className="color-swatch color-teal-800"></li>
        <li className="color-swatch color-teal-900"></li>
      </ul>
    </Layout>
  )
}

export default ColorsPage
